/**
 * @file Skills.tsx
 * @description This file contains the Skills component, which is used to display the skills section of the web application.
 * @author Sebastiaan Koedam
 */

import '../styles/components/skills.css';
import { SkillCategoryType, skills } from '../data/constants';

/**
 * Skills component
 * @returns Skills component
 */
export function Skills() {
    return (
        <section id='skills'>
            <div className='skills-container'>
                <h1>Skills</h1>
                <p className='subtitle'>Here are some of my skills which I have been working on</p>
                <div className='skill-container'>
                    {skills.map((category: SkillCategoryType, index: number) => (
                        <SkillCard key={index} content={category} />
                    ))}
                </div>
            </div>
        </section>
    );
}

/**
 * SkillCard component
 * @param title Title of the skill card
 * @param desc Description of the skill card
 * @returns SkillCard component
 */
export function SkillCard({ content }: { content: SkillCategoryType }) {
    return (
        <div className='skill-card'>
            <h2 className='cat-title'>{content.title}</h2>
            <div className='card-content'>
                {content.skills.map((skill, index) => (
                    <div className='skill' key={index}>
                        <img className='skill-image' src={skill.image} alt={skill.name} />
                        <p className='skill-name'>{skill.name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}
