/**
 * @file Education.tsx
 * @description This file contains the Education component, which is used to display the education section of the web application.
 * @author Sebastiaan Koedam
 */

import '../styles/components/education.css';
import { EducationInfo, educations } from '../data/constants';

/**
 * Education component
 * @returns Education component
 */
export function Education() {
    return (
        <section id='education'>
            <h1>Education</h1>
            <p className='subtitle'>My education has been a journey of self-discovery and growth. My educational details are as follows.</p>
            <div className='edu-container'>
                {educations.map((education: EducationInfo, index: number) => (
                    <EducationCard content={education} key={index}/>
                ))}
            </div>
        </section>
    );
}

/**
 * EducationCard component
 * @param title Title of the education card
 * @param desc Description of the education card
 * @returns EducationCard component
 */
export function EducationCard({ content }: { content: EducationInfo }) {
    return (
        <div className='edu-card'>
            <div className='edu-top'>
                <img className='edu-img' src={content.img} alt={content.school} />
                <div className='edu-info'>
                    <h3 className='edu-school'>{content.school}</h3>
                    <p className='edu-degree'>{content.degree}</p>
                    <span className='edu-date'>{content.date}</span>
                </div>
            </div>
            <span className='edu-desc'>{content.desc}</span>
        </div>
    );
}
