/**
 * @file About.tsx
 * @description This file contains the About component, which is used to display the about section of the web application.
 * @author Sebastiaan Koedam
 */

import BgAnimation from './BgAnimation';
import Typewriter from 'typewriter-effect';
import { Bio } from '../data/constants';
import '../styles/components/about.css';

/**
 * About component
 * @returns About component
 */
export function About() {
    const resumePDF = require('../resources/resume.pdf');

    return (
        <section id='about'>
            <div className='about-container'>
                <div className='about-intro'>
                    <h2>
                        Hi, I am <br /> {Bio.name}
                    </h2>
                    <div className='text-loop'>
                        <h3>I am a</h3>
                        <span>
                            <Typewriter
                                options={{
                                    strings: Bio.roles,
                                    autoStart: true,
                                    loop: true,
                                }}
                            />
                        </span>
                    </div>
                    <p className='about-desc'>{Bio.description}</p>
                    <button className='resume-button' onClick={() => window.open(resumePDF, '_blank')}>
                        View Resume
                    </button>
                </div>
                <div className='image-container'>
                    <div className='about-img'>
                        <img src={Bio.image} alt='about pf' />
                    </div>
                    <div className='animation-container'>
                        <BgAnimation />
                    </div>
                </div>
            </div>
        </section>
    );
}
