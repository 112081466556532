/**
 * @file Experience.tsx
 * @description This file contains the Experience component, which is used to display the experience section of the web application.
 * @author Sebastiaan Koedam
 */

import '../styles/components/experience.css';
import { ExperienceInfo, experiences } from '../data/constants';

/**
 * Experience component
 * @returns Experience component
 */
export function Experience() {
    return (
        <section id='experience'>
            <div className='experience-container'>
                <h1>Experience</h1>
                <p className='subtitle'>
                    My work experience, which includes working on various projects at different companies, is as
                    follows.
                </p>
                <div className='experience-wrapper'>
                    {experiences.map(companyExperience => (
                        <div key={companyExperience.company} className='exp-card'>
                            <div className='company-content'>
                                <div className='company-info'>
                                    <img
                                        className='exp-image'
                                        src={companyExperience.imgUrl}
                                        alt={companyExperience.company}
                                    />
                                    <h2 className='exp-company'>{companyExperience.company}</h2>
                                </div>
                                {companyExperience.companyUrl && (
                                    <button
                                        className='exp-company-button'
                                        onClick={() => window.open(companyExperience.companyUrl, '_blank')}
                                    >
                                        getgood
                                    </button>
                                )}
                            </div>
                            <div className='exp-container'>
                                {companyExperience.experiences.map((experience, index) => (
                                    <ExperienceCard
                                        key={index}
                                        content={experience}
                                        company={companyExperience.company}
                                        img={companyExperience.imgUrl}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

/**
 * ExperienceCard component
 * @param title Title of the experience card
 * @param desc Description of the experience card
 * @returns ExperienceCard component
 */
export function ExperienceCard({ content, company, img }: { content: ExperienceInfo; company: string; img: string }) {
    return (
        <div className='exp-content'>
            <div className='h-line' />
            <div className='exp-box'>
                <div className='exp-left'>
                    <div className='exp-top'>
                        <div className='exp-body'>
                            <h3 className='exp-role'>{content.role}</h3>
                            <p className='exp-date'>{content.date}</p>
                        </div>
                    </div>
                    <span className='exp-desc'>{content.desc}</span>
                </div>
                <div className='exp-right'>
                    <h4>Skills acquired</h4>
                    <ul className='exp-skills'>
                        {content.skills.map((skill, index) => (
                            <li key={index} className='exp-skill'>
                                {skill}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}
