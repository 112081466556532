import '../styles/components/BgAnimation.css';
import React, { useEffect } from 'react';

const BgAnimation: React.FC = () => {
  const updateMarginTop = () => {
    const width = window.innerWidth;
    
    // Define breakpoints and margin values
    const minWidth = 300;
    const maxWidth = 600;
    const minMarginTop = 35; // percentage at minWidth
    const maxMarginTop = 8;  // percentage at maxWidth

    // Default margin top when width is more than 600px
    const defaultMarginTop = '4%';

    let marginTop: string;

    if (width <= maxWidth) {
      if (width <= minWidth) {
        marginTop = `${minMarginTop}%`; // Margin at the smallest screen width
      } else {
        // Calculate the margin top based on the viewport width between 300px and 600px
        const ratio = (width - minWidth) / (maxWidth - minWidth);
        marginTop = `${minMarginTop - (ratio * (minMarginTop - maxMarginTop))}%`;
      }
    } else {
      marginTop = defaultMarginTop; // Apply default margin if width is greater than 600px
    }

    const element = document.querySelector('.animation-container');
    if (element) {
      (element as HTMLElement).style.marginTop = marginTop;
    }
  };

  useEffect(() => {
    updateMarginTop();
    window.addEventListener('resize', updateMarginTop);
    return () => window.removeEventListener('resize', updateMarginTop);
  }, []);

  return (
    <div className="bg-animation">
      <svg
        className="BgAnimation__svg"
        viewBox="0 0 602 602"
        fill="#FF0000"
        xmlns="http://www.w3.org/2000/svg"
        width="100%" 
        height="100%" 
      >
        <g opacity="1">
          <circle
            cx="301"
            cy="348"
            r="210"
            stroke="url(#paint_linear)"
            fill="none"
          />
          <circle
            cx="301"
            cy="348"
            r="220"
            stroke="url(#paint_linear)"
            fill="none"
          />
          <circle
            cx="301"
            cy="348"
            r="230"
            stroke="url(#paint_linear)"
            fill="none"
          />
          <circle
            cx="301"
            cy="348"
            r="240"
            stroke="url(#paint_linear)"
            fill="none"
          />
          <circle
            cx="301"
            cy="348"
            r="250"
            stroke="url(#paint_linear)"
            fill="none"
          />
          <circle
            cx="301"
            cy="348"
            r="260"
            stroke="url(#paint_linear)"
            fill="none"
          />
          <circle
            cx="301"
            cy="348"
            r="270"
            stroke="url(#paint_linear)"
            fill="none"
          />
          <circle
            cx="301"
            cy="348"
            r="280"
            stroke="url(#paint_linear)"
            fill="none"
          />
          <path
            id="circle_0"
            d="M101 301 a200 200 0 0 1 400 0 a200 200 0 0 0 -400 0"
            fill="#FF2400"
          />
          <path
            id="circle_1"
            d="M151 351 a150 150 0 0 1 300 0 a150 150 0 0 0 -300 0"
            fill="#D10000"
          />
          <path
            id="circle_2"
            d="M201 401 a100 100 0 0 1 200 0 a100 100 0 0 0 -200 0"
            fill="#A30000"
          />
          <path
            id="circle_3"
            d="M251 451 a50 50 0 0 1 100 0 a50 50 0 0 0 -100 0"
            fill="#DC143C"
          />
          <path
            id="circle_4"
            d="M301 501 a40 40 0 0 1 80 0 a40 40 0 0 0 -80 0"
            fill="#DA012D"
          />
          <path
            id="circle_5"
            d="M351 551 a30 30 0 0 1 60 0 a30 30 0 0 0 -60 0"
            fill="#9B111E"
          />
          <path
            id="circle_6"
            d="M401 601 a20 20 0 0 1 40 0 a20 20 0 0 0 -40 0"
            fill="#FF0000"
          />
        </g>
        <ellipse cx="301" cy="301" rx="2" ry="2" fill="#FF2400">
          <animateMotion
            dur="10s"
            repeatCount="indefinite"
            rotate="auto"
          >
            <mpath xlinkHref="#circle_0" />
          </animateMotion>
        </ellipse>
        <ellipse cx="301" cy="301" rx="3" ry="3" fill="#D10000">
          <animateMotion
            dur="8s"
            repeatCount="indefinite"
            rotate="auto"
          >
            <mpath xlinkHref="#circle_1" />
          </animateMotion>
        </ellipse>
        <ellipse cx="301" cy="301" rx="4" ry="4" fill="#A30000">
          <animateMotion
            dur="6s"
            repeatCount="indefinite"
            rotate="auto"
          >
            <mpath xlinkHref="#circle_2" />
          </animateMotion>
        </ellipse>
        <ellipse cx="301" cy="301" rx="5" ry="5" fill="#DC143C">
          <animateMotion
            dur="4s"
            repeatCount="indefinite"
            rotate="auto"
          >
            <mpath xlinkHref="#circle_3" />
          </animateMotion>
        </ellipse>
        <ellipse cx="301" cy="301" rx="6" ry="6" fill="#DA012D">
          <animateMotion
            dur="7s"
            repeatCount="indefinite"
            rotate="auto"
          >
            <mpath xlinkHref="#circle_4" />
          </animateMotion>
        </ellipse>
        <ellipse cx="301" cy="301" rx="7" ry="7" fill="#9B111E">
          <animateMotion
            dur="5s"
            repeatCount="indefinite"
            rotate="auto"
          >
            <mpath xlinkHref="#circle_5" />
          </animateMotion>
        </ellipse>
        <ellipse cx="301" cy="301" rx="8" ry="8" fill="#FF0000">
          <animateMotion
            dur="3s"
            repeatCount="indefinite"
            rotate="auto"
          >
            <mpath xlinkHref="#circle_6" />
          </animateMotion>
        </ellipse>
        <defs>
          <linearGradient
            id="paint_linear"
            x1="165.54"
            y1="93.9578"
            x2="140.472"
            y2="118.996"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DC143C" />
            <stop offset="1" stopColor="#DC143C" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default BgAnimation;
