/**
 * @file index.tsx
 * @description This file contains the main entry point for the web application.
 * It renders the Navigator and Pages components, which are used to navigate between different pages in the application.
 * @author Sebastiaan Koedam
 */

import ReactDOM from 'react-dom/client';
import { Navigator } from './components/Navigator';
import { About } from './components/About';
import { Skills } from './components/Skills';
import { Experience } from './components/Experience';
import { Education } from './components/Education';
import { Contact } from './components/Contact';
import { Footer } from './components/Footer';
import './styles/styles.css';

// Select the root element
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// Render the web app
root.render(
    <div className='page-container'>
        <Navigator />
        <div className='page-content'>
            <About />
            <Skills />
            <Experience />
            <Education />
            <Contact />
        </div>
        <Footer />
    </div>,
);
