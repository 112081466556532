/**
 * @file Footer.tsx
 * @description This file contains the Footer component, which is used to display the footer section of the web application.
 * @author Sebastiaan Koedam
 */

import { Bio } from '../data/constants';
import '../styles/components/footer.css';

/**
 * Footer component
 * @returns Footer component
 */
export function Footer() {
    return (
        <footer className='footer-container'>
            <div className='footer-wrapper'>
                <h2 className='footer-name'>Sebastiaan Koedam</h2>
                <nav className='footer-nav'>
                    <FooterLink title='About' targetSection='#about' />
                    <FooterLink title='Skills' targetSection='#skills' />
                    <FooterLink title='Experience' targetSection='#experience' />
                    <FooterLink title='Education' targetSection='#education' />
                    <FooterLink title='Contact' targetSection='#contact' />
                </nav>
                <div className='social-media-icons'>
                    <FooterIcon
                        link={Bio.linkedin}
                        src='https://svgrepo.com/show/108614/linkedin.svg'
                        alt='LinkedIn'
                    />
                    <FooterIcon link={Bio.github} src='https://www.svgrepo.com/show/521688/github.svg' alt='Github' />
                </div>
                <p className='copyright'>&copy; 2024 Sebastiaan Koedam. All rights reserved.</p>
            </div>
        </footer>
    );
}

/**
 * FooterLink component
 * @param title Title of the footer link
 * @param targetSection Target section of the footer link
 * @returns FooterLink component
 */
function FooterLink({ title, targetSection }: { title: string; targetSection: string }) {
    return (
        <a href={targetSection} className='footer-nav-link'>
            {title}
        </a>
    );
}

/**
 * FooterIcon component
 * @param link URL to the social media profile
 * @param src URL of the icon image
 * @param alt Alt text for the image
 * @returns FooterIcon component
 */
function FooterIcon({ link, src, alt }: { link: string; src: string; alt: string }) {
    return (
        <a href={link} className='social-media-icon' target='_blank' rel='noopener noreferrer'>
            <img src={src} alt={alt} className='social-icon-img' />
        </a>
    );
}
